import {
  FacebookIcon,
  FacebookShareButton,
  GooglePlusIcon,
  GooglePlusShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WeiboIcon,
  WeiboShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'

import { Box } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledShareContainer = styled(Box)`
  display: flex;
  flex-flow: row wrap;
  margin: 50px -20px;
  .SocialMediaShareButton {
    vertical-align: top;
    text-align: center;
    padding: 20px;

    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }

    .shareButton {
      border-radius: 5px;
    }
  }
`

const Share = ({ socialConfig, tags }) => (
  <StyledShareContainer>
    <FacebookShareButton url={socialConfig.config.url}>
      <FacebookIcon size={42} round={true} />
    </FacebookShareButton>
    <TwitterShareButton
      url={socialConfig.config.url}
      title={socialConfig.config.title}
      via={socialConfig.twitter.split('@').join('')}
      hashtags={tags}
    >
      <TwitterIcon size={42} round={true} />
    </TwitterShareButton>
    <GooglePlusShareButton url={socialConfig.config.url}>
      <GooglePlusIcon size={42} round={true} />
    </GooglePlusShareButton>
    <LinkedinShareButton
      url={socialConfig.config.url}
      title={socialConfig.config.title}
    >
      <LinkedinIcon size={42} round={true} />
    </LinkedinShareButton>
    <RedditShareButton
      url={socialConfig.config.url}
      title={socialConfig.config.title}
    >
      <RedditIcon size={42} round={true} />
    </RedditShareButton>
    <WhatsappShareButton
      url={socialConfig.config.url}
      title={socialConfig.config.title}
    >
      <WhatsappIcon size={42} round={true} />
    </WhatsappShareButton>
    <WeiboShareButton
      url={socialConfig.config.url}
      title={socialConfig.config.title}
      image="https://www.cybermiles.io/twitterCardLogo.jpg"
    >
      <img
        className="Demo__some-network__custom-icon"
        src="https://staging.cybermiles.io/weibo-icon.png"
        alt="Weibo share button"
        style={{ height: '42px', width: '42px' }}
      />
    </WeiboShareButton>
    <TelegramShareButton
      url={socialConfig.config.url}
      title={socialConfig.config.title}
    >
      <TelegramIcon size={42} round={true} />
    </TelegramShareButton>
  </StyledShareContainer>
)

Share.propTypes = {
  socialConfig: PropTypes.shape({
    twitter: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}
Share.defaultProps = {
  tags: [],
}

export default Share
