import { Box, Grommet } from 'grommet'
import React, { Component } from 'react'
import {
  StyledColumnContainer,
  StyledColumnsContainer,
  StyledContainer,
  StyledH2,
  StyledH3,
  StyledP,
} from '../components/reusables/styles'
import { sizes, theme } from '../utilities/theme.js'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'

import CssBaseline from '@material-ui/core/CssBaseline'
import Footer from '../components/navigation/footer'
import Header from '../components/navigation/header'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Share from '../components/reusables/share'
import burgerIcon from '../assets/images/favicon-cybermiles.png'
import { deepMerge } from 'grommet/utils'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { grommet } from 'grommet/themes'

const { colors } = theme

const customBreakpoints = deepMerge(grommet, {
  global: {
    colors: colors,
    breakpoints: sizes,
    font: {
      family: 'Montserrat',
    },
    edgeSize: {
      large: '64px',
      xlarge: '100px',
    },
    h1: {
      fontSize: '44px',
      lineHeight: 'normal',
      fontWeight: 'normal',
    },
    h2: {
      fontSize: '32px',
      lineHeight: 'normal',
      fontWeight: 'normal',
    },
    h3: {
      fontSize: '22px',
      lineHeight: 'normal',
      fontWeight: 'normal',
    },
    p: {
      fontSize: '18px',
      lineHeight: '32px',
      fontWeight: 'normal',
    },
  },
})

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Montserrat', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
    h1 {
      font-size: 44px;
      line-height: normal;
      font-weight: normal;
    }
    h2 {
      font-size: 32px;
      line-height: normal;
      font-weight: normal;
    }
    h3 {
      font-size: 22px;
      line-height: normal;
      font-weight: normal;
    }
    p {
      font-size: 18px;
      line-height: 1.5;
      font-weight: normal;
    }
  }
`

const StyledPostContainer = styled(StyledContainer)`
  align-items: start;
`

const StyledHero = styled(Box)`
  width: 100%;
  max-height: 356px;
`

const StyledAuthor = styled.p`
  margin-top: 80px;
`

const StyledH1 = styled.h1`
  color: ${props => props.theme.global.colors.primaryDark};
  font-size: 44px;
`

const StyledContent = styled(Box)``
class BlogPostTemplate extends Component {
  render() {
    const { data } = this.props
    const { prismicBlogPost: post, site } = data
    const {url, twitter } = site.siteMetadata
    const messages = require(`../i18n/locales/${
      post.lang
    }/navigation/header.js`)
    const menuItems = messages.default.items

    return (
      <Box>
        <SEO
          title={post.data.title.text}
          description={post.data.description.text}
        />
        <Grommet theme={customBreakpoints}>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <div>
              <GlobalStyle />
              <Header menuItems={menuItems} post={post} />

              <StyledPostContainer componentName="Post" shade="light">
                <StyledAuthor>{post.data.author.text}</StyledAuthor>
                <StyledH1 className="section-headline">
                  {post.data.title.text}
                </StyledH1>
                {post.data.hero.localFile && (
                  <StyledHero>
                    <Img
                      className="heroImage"
                      alt={post.data.title.text}
                      sizes={post.data.hero.localFile.childImageSharp.fluid}
                    />
                  </StyledHero>
                )}

                <StyledContent
                  dangerouslySetInnerHTML={{
                    __html: post.data.content.html,
                  }}
                />
                <Share
                  socialConfig={{
                    twitter,
                    config: {
                      url: `${url}/${post.lang}/blog/${post.uid}/`,
                      title: post.data.title.text,
                    },
                  }}
                />
              </StyledPostContainer>
              <Footer locale={post.lang} />
            </div>
          </ThemeProvider>
        </Grommet>
      </Box>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query PostBySlug($uid: String!, $locale: String!) {
    site {
      siteMetadata {
        url
        twitter
      }
    }
    prismicBlogPost(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      lang
      alternate_languages {
        uid
        lang
        type
      }
      data {
        title {
          text
        }
        description {
          text
        }
        author {
          text
        }
        hero {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1240, maxHeight: 356) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        content {
          html
        }
      }
    }
  }
`
